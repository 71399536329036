import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageSection from '../components/Sections/PageSection';
import PageSectionWithImage from '../components/Sections/PageSectionWithImage';
import StandardFrame from '../components/StandardFrame';
import PageSectionTitle from '../components/Sections/PageSectionTitle';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ContactForm } from '../components/Sections/ContactForm';

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Helmet title='Szkolenia z rozliczeń NFZ' />
      <StandardFrame>
        <PageSectionWithImage
          maxWidth='lg'
          color='white'
          bgimage={
            <StaticImage
              style={{ height: '100%' }}
              layout='fullWidth'
              quality={95}
              src='../images/szkolenia_z_rozliczen_nfz_hero.jpg'
              alt=''
            />
          }
        >
          <PageSectionTitle>Szkolenia z rozliczeń NFZ</PageSectionTitle>

          <Box>
            <Typography color='white' gutterBottom>
              Usługa ta skierowana jest do osób zajmujących się sprawozdawczością oraz comiesięcznymi rozliczeniami
              placówki z Narodowym Funduszem Zdrowia.
            </Typography>
          </Box>
        </PageSectionWithImage>
        <PageSection bgcolor='background.default' maxWidth='lg'>
          <Typography marginBottom={2}>
            Dzięki szkoleniu zdobędziesz praktyczną wiedzę i umiejętności, w zakresie przeprowadzania rozliczeń z NFZ w
            systemie Mediporta, dzięki czemu wykonanie kompletnego i poprawnego rozliczenia nie będzie stanowiło
            najmniejszego problemu. Szkolenia są prowadzone on-line, przez osobę posiadającą doświadczenie w
            rozliczeniach z NFZ-em, m.in. placówek POZ, AOS oraz STM. Usługa jest skierowana zarówno do osób, które chcą
            usystematyzować dotychczas zdobytą wiedzę na temat rozliczeń, jak również do pracowników placówki, którzy
            dopiero zaczynają swoją przygodę z rozliczeniami.
          </Typography>

          <Stack direction={isMobile ? 'column' : 'row'} marginTop={4} gap={4} alignItems={'flex-start'}>
            <Stack>
              <Typography variant='h6' component={'span'}>
                Dlaczego warto skorzystać z usługi?
              </Typography>
              <List
                dense
                sx={{
                  listStyle: 'disc',
                  listStylePosition: 'inside'
                }}
              >
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  szkolenie zdalne, prowadzone przez aplikację Teams
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  szkolenie prowadzone przez osobę posiadającą doświadczenie w rozliczeniach z NFZ-em placówek POZ, AOS
                  oraz STM
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  omówienie terminarza rozliczeń
                </ListItem>

                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  objaśnienie zasad przeprowadzania rozliczeń oraz sprawozdawczości poszczególnych raportów (m.in.
                  raport deklaracji POZ (lista aktywna pacjentów), raport statystyczny, raport kolejek oczekujących,
                  rachunki i faktury VAT dla NFZ),
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  prezentacja czynności związanych z generowaniem poszczególnych raportów i importem raportów zwrotnych
                  w systemie Mediporta
                </ListItem>
                <ListItem
                  sx={{
                    display: 'list-item'
                  }}
                >
                  omówienie wymagań związanych z uzupełnianiem elektronicznej dokumentacji medycznej na cele raportowe
                </ListItem>
              </List>
            </Stack>
            <Stack>
              <Card
                variant='outlined'
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  width: isMobile ? '100%' : 325,
                  backgroundColor: 'tertiary.main'
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Box sx={{ backgroundColor: 'primary.main' }} paddingX={2} paddingY={1}>
                    <Typography
                      textTransform={'uppercase'}
                      color={'background.default'}
                      textAlign={'center'}
                      variant='h4'
                      component={'div'}
                    >
                      Cena szkolenia
                    </Typography>
                  </Box>
                  <Stack alignItems={'center'} marginY={2}>
                    <Box>
                      <Typography component={'span'} fontSize={'1.15rem'} fontWeight={700}>
                        od
                      </Typography>
                      <Typography component={'span'} fontWeight={700} fontSize={'2rem'}>
                        {' '}
                        299 zł
                      </Typography>
                      <Typography component={'span'} fontSize={'1.15rem'} fontWeight={700}>
                        / godzinę
                      </Typography>
                    </Box>
                    <Divider
                      flexItem
                      variant='middle'
                      sx={{
                        borderColor: 'secondary.divider',
                        borderWidth: 1,
                        borderBottom: 'none',
                        marginBottom: 0.5
                      }}
                    />
                    <Typography>cena netto</Typography>
                  </Stack>
                  <Box sx={{ backgroundColor: 'secondary.main' }} paddingX={1} marginBottom={2}>
                    <Typography variant='body1' textAlign={'center'}>
                      Średni czas szkolenia w jednym bloku tematycznym to <b>1h</b>, w zależności od ustalonego zakresu.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    flexDirection: 'column',
                    gap: 1.5,
                    paddingBottom: 3,
                    paddingX: 4
                  }}
                >
                  <Typography variant='body2' textAlign={'center'}>
                    Aby zamówić szkolenie, wypełnij formularz kontaktowy
                  </Typography>
                </CardActions>
              </Card>
            </Stack>
          </Stack>
        </PageSection>
        <ContactForm bgcolor={'tertiary.main'} />
      </StandardFrame>
    </>
  );
};
